import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { config, isAuthenticate } from "./services/utils";

/* ------- Components -------- */
import Sidebar from "./components/sidebar/sidebar";
import Header from "./components/header/header";
import LoadScreen from "./services/login/LoadingScreen";
/* -------------------------- */

/* --------- Pages ---------- */
import DashboardBolsao from "./pages/dashboard/dashboard";
import DashboardEnvios from "./pages/dashEnvios/dashboard";
import DashboardHibernation from "./pages/dashHibernation/dashHibernation";
import Mensagens from "./pages/messages/messages";
import BolsaoLog from "./pages/summarize/summarizeBolsao";
import CdrLog from "./pages/cdr/cdrPage";
import CdrItr from "./pages/cdr/cdrPageItr";
import HsmPeriod from "./pages/summarize/summarizeHSM";
import HsmList from "./pages/summarize/summarizeHSMList";
import SmsPeriod from "./pages/summarize/summarizeSMS";
import SmsList from "./pages/summarize/summarizeSMSList";
import SendList from "./pages/sendList/sendList";
import History from "./pages/history/history";
import genHistoy from "./pages/history/genesysHistory";
import Hibernation from "./pages/hibernation/hibernation";
import Wrapup from "./pages/wrapup/Wrapup";
import Tags from "./pages/tags/Tags";
import ReportHibernation from "./pages/summarize/summarizeHibernation";
import ReportHibernation2 from "./pages/summarize/summarizeHibernation2";
import AudioRecorder from "./pages/history/historyFunctions/audioRecorder/audioRecorder";
import ScreenHSM from "./pages/screenHSM/screenHSM";
import TimedHSM from "./pages/timedHSM/timedHSM";
import WidgetTimedHSM from "./pages/timedHSM/widget/widget";
import TagCloud from "./pages/tagcloud/TagCloud";
import Milestone from "./pages/milestone/Milestone";
import SentimentoAnal from "./pages/summarize/summarizeSentimentoAnal";
import SentimentoCons from "./pages/summarize/summarizeSentimentoCons";
import HistoryAnal from "./pages/summarize/summarizeHistoryAnal";
import Interactions from "./pages/dashInteraction/DashInteraction";
import DashYear from "./pages/dashYear/dashYear";
import MilestonePeriodo from "./pages/milestonePeriodo/MilestonePeriodo";
import DashUra from "./pages/dashUra/dashUra";
import DashComeback from "./pages/comebackHibernation/comebackDash";
import ComebackHistory from "./pages/comebackHibernation/historyComeback";
import Monitoring from "./pages/monitoring/monitor.js";
import ServiceFlowReport from "./pages/summarize/summarizeServiceFlow";
import GeneralConsolidatedReport from "./pages/summarize/summarizeGeneralConsolidated";
import AttemptsConsolidatedReport from "./pages/summarize/summarizeAttemptsConsolidated";
import ContactsTaggingReport from "./pages/summarize/summarizeContactsTagging";
import MailingStatusReport from "./pages/summarize/summarizeMailingStatus";
import SummarizeConsolidateBackOffice from "./pages/summarize/summarizeConsolidateBackOffice.js";
import SummarizeHSMBackOffice from "./pages/summarize/summarizeHSMBackOffice.js";
import SummarizeMailingGenesys from "./pages/summarize/summarizeMailingGenesys.js";
import SummarizeAgentMessage from "./pages/summarize/summarizeAgentMessage.js";
import SummarizeHSMListCRM from "./pages/summarize/summarizeHSMListCRM.js";
import SummarizeServiceFlowCRM from "./pages/summarize/summarizeServiceFlowCRM.js";
import summarizeReactive from "./pages/summarize/summarizeReactive.js";
import RDStationConfig from "./pages/rdIntegration/rdStationConfig.js";
import SummarizeRDStation from "./pages/summarize/summarizeRDStation.js";
import dashboardHibernationVia from "./pages/dashboardHibernationVia/dashboardHibernation.js";
import DashboardHibernationStatus from "./pages/dashboardHibernationVia/dashboardHibernationStatus/dashboardHibernationStatus.js";
import DashboardHibernationMap from "./pages/dashboardHibernationVia/dashboardHibernationMap/dashboardHibernationMap.js";
import DashboardTables from "./pages/dashboardHibernationVia/dashboardTable/dashboardUniqueNumberGeneral.js";
import DashboardUniqueNumberRanking from "./pages/dashboardHibernationVia/dashboardTable/dashboardUniqueNumberRanking.js";
import DashboardSACWhatsapp from "./pages/dashboardHibernationVia/dashboardSACWhatsapp/dashboardSACWhatsapp.js";
import DashboardSACWhatsappHours from "./pages/dashboardHibernationVia/dashboardSACWhatsappHours/dashboardSACWhatsappHours.js";
import Tools from "./pages/tools/tools";
import Period from "./pages/interactionPeriod/interactionPeriod";
import Blacklist from "./pages/blacklist/blacklist";
import YearAdapter from "./pages/holidayAdapter/registerPage/mainRegister";
import Schedule from "./pages/scheduleCreate/registerPage/scheduleRegister";
// import Association from './pages/associationSchedule/association';
import AssociationV1 from "./pages/associationScheduleSimple";
import defaultTemplate from "./templates/defaultTemplate";
import blankTemplate from "./templates/blankTemplate";
/* -------------------------- */

// ------- Redirect ----------//
const PrivateRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isAuthenticate() ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect to={{ pathname: "/app/verify" }} />
      )
    }
  />
);

// -------------------------- //

function showSideview() {
  if (
    window.location.pathname.includes("ghistory") ||
    window.location.pathname.includes("historyGenesys") ||
    window.location.pathname.includes("recordingAudio") ||
    window.location.pathname.includes("screenHSM") ||
    window.location.pathname.includes("screenLoging") ||
    window.location.pathname.includes("test") ||
    window.location.pathname.includes("widgettimedHSM") ||
    window.location.pathname.includes("scheduled")
  ) {
    return null;
  }
  return (
    <div>
      {/* <Sidebar />
      <Header /> */}
    </div>
  );
}

const Routes = () => {
  const ghURL = localStorage.getItem("ghURL");
  const widget = localStorage.getItem("widget");
  const scheduled = localStorage.getItem("scheduled");
  localStorage.removeItem("ghURL");
  localStorage.removeItem("widget");
  localStorage.removeItem("scheduled");

  const defaultPage = config.application.client.defaultRoute;
  const baseName = config.application.client.baseName;

  return (
    <BrowserRouter basename={baseName}>
      <Switch>
        <PrivateRoute exact path="/">
          <Redirect to={`/app${defaultPage}`} />
        </PrivateRoute>

        <PrivateRoute exact path="/app">
          <Redirect to={`/app${defaultPage}`} />
        </PrivateRoute>

        <PrivateRoute exact path="/historyGenesys" layout={blankTemplate}>
          <Redirect to={ghURL ? ghURL : `/app${defaultPage}`} />
        </PrivateRoute>

        <PrivateRoute exact path="/screenLoging" layout={blankTemplate}>
          <Redirect to={widget ? "/app/screenHSM" : `/app${defaultPage}`} />
        </PrivateRoute>

        <PrivateRoute exact path="/scheduled" layout={blankTemplate}>
          <Redirect
            to={scheduled ? "/app/widgettimedHSM" : `/app${defaultPage}`}
          />
        </PrivateRoute>

        {/* -------------------------------- Dashboards -------------------------------------------*/}
        <PrivateRoute
          exact
          path="/app/dashboard"
          layout={defaultTemplate}
          component={DashboardEnvios}
        />
        <PrivateRoute
          exact
          path="/app/dashboard/bolsao"
          component={DashboardBolsao}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/dashboard/hibernation"
          component={DashboardHibernation}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/dashboard/milestone"
          component={Milestone}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/dashboard/interactions"
          component={Interactions}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/dashboard/tagcloud"
          component={TagCloud}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/dashboard/yearinteractions"
          component={DashYear}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/dashboard/ura"
          component={DashUra}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/dashboard/comeback"
          component={DashComeback}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/dashboard/hibernation-interactions"
          component={dashboardHibernationVia}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/dashboard/hibernation-status"
          component={DashboardHibernationStatus}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/dashboard/hibernation-map"
          component={DashboardHibernationMap}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/dashboard/unique-number"
          component={DashboardTables}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/dashboard/unique-number-ranking"
          component={DashboardUniqueNumberRanking}
          layout={defaultTemplate}
        />
        <PrivateRoute
          path="/app/dashboard/sac-whatsapp"
          component={DashboardSACWhatsapp}
          layout={defaultTemplate}
        />
        <PrivateRoute
          path="/app/dashboard/sac-whatsapp-hours"
          component={DashboardSACWhatsappHours}
          layout={defaultTemplate}
        />

        {/* -------------------------------- Relatórios -------------------------------------------*/}
        <PrivateRoute
          exact
          path="/app/summarize/bolsao"
          component={BolsaoLog}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/Cdrlog"
          component={CdrLog}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/CdrItr"
          component={CdrItr}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/hsm"
          component={HsmPeriod}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/hsmlist"
          component={HsmList}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/sms"
          component={SmsPeriod}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/smslist"
          component={SmsList}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/hibernation"
          component={ReportHibernation}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/SentimentoA"
          component={SentimentoAnal}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/SentimentoC"
          component={SentimentoCons}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/HistoryA"
          component={HistoryAnal}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/milestone"
          component={MilestonePeriodo}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/hibernation/report2"
          component={ReportHibernation2}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/service-flow"
          component={ServiceFlowReport}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/general-consolidated"
          component={GeneralConsolidatedReport}
        />
        <PrivateRoute
          exact
          path="/app/summarize/attempts-consolidated"
          component={AttemptsConsolidatedReport}
        />
        <PrivateRoute
          exact
          path="/app/summarize/contacts-tags"
          component={ContactsTaggingReport}
        />
        <PrivateRoute
          exact
          path="/app/summarize/mailing-status"
          component={MailingStatusReport}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/consolidate-backoffice"
          component={SummarizeConsolidateBackOffice}
          layout={defaultTemplate}
        />

        <PrivateRoute
          exact
          path="/app/summarize/hsm-backoffice"
          component={SummarizeHSMBackOffice}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/summarize/mailing-genesys"
          component={SummarizeMailingGenesys}
          layout={defaultTemplate}
        />
        <PrivateRoute
          path="/app/summarize/agent-message"
          component={SummarizeAgentMessage}
          layout={defaultTemplate}
        />
        <PrivateRoute
          path="/app/summarize/crm-service-flow"
          component={SummarizeServiceFlowCRM}
          layout={defaultTemplate}
        />
        <PrivateRoute
          path="/app/summarize/crm-hsm-list-detail"
          component={SummarizeHSMListCRM}
          layout={defaultTemplate}
        />
        <PrivateRoute
          path="/app/summarize/rd-station-mailing"
          component={SummarizeRDStation}
          layout={defaultTemplate}
        />
        <PrivateRoute
          path="/app/summarize/reactive"
          component={summarizeReactive}
          layout={defaultTemplate}
        />

        {/* ----------------------------------- Cadastros ----------------------------------------*/}
        <PrivateRoute
          exact
          path="/app/wrapup"
          component={Wrapup}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/tags"
          component={Tags}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/hibernation"
          component={Hibernation}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/messages"
          component={Mensagens}
          layout={defaultTemplate}
        />
        <PrivateRoute exact path="/app/interactionPeriod" component={Period} />
        <PrivateRoute
          exact
          path="/app/blacklist"
          component={Blacklist}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/holidays"
          component={YearAdapter}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/schedule"
          component={Schedule}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/association"
          component={AssociationV1}
          layout={defaultTemplate}
        />

        {/* ------------------------------- Funcionalidades -------------------------------------*/}
        <PrivateRoute
          exact
          path="/app/sendlist"
          component={SendList}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/history"
          component={History}
          layout={defaultTemplate}
        />

        <PrivateRoute
          exact
          path="/app/comebackHistory"
          component={ComebackHistory}
          layout={defaultTemplate}
        />
        <PrivateRoute
          exact
          path="/app/TimedHSM"
          layout={defaultTemplate}
          component={TimedHSM}
        />
        <PrivateRoute
          exact
          path="/app/tools"
          layout={defaultTemplate}
          component={Tools}
        />
        <PrivateRoute
          exact
          path="/app/rdStation-integration"
          layout={defaultTemplate}
          component={RDStationConfig}
        />

        {/* ------------------------------- Funções do Agente -------------------------------------*/}
        <PrivateRoute
          exact
          path="/app/ghistory/:telefone/:origin/:token"
          component={genHistoy}
          layout={blankTemplate}
        />
        <PrivateRoute
          exact
          path="/app/recordingAudio/:telefone/:origin/:token"
          component={AudioRecorder}
          layout={blankTemplate}
        />
        <PrivateRoute
          exact
          path="/app/widgettimedHSM"
          component={WidgetTimedHSM}
          layout={blankTemplate}
        />
        <PrivateRoute
          exact
          path="/app/screenHSM"
          component={ScreenHSM}
          layout={blankTemplate}
        />
        <PrivateRoute
          exact
          path="/app/screenHSM/:telefone"
          component={ScreenHSM}
          layout={blankTemplate}
        />

        {/* ------------------------------- Monitores ------------------------------------- */}
        <PrivateRoute
          exact
          path="/app/monitoring/"
          component={Monitoring}
          layout={defaultTemplate}
        />

        {/* ------------------------------------ Others -----------------------------------------*/}
        <Route exact path="/app/verify" component={LoadScreen} />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
