import "./genesys.css";
import React, { useState, useEffect } from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import NewGenesysTable from "../../components/tables/newGenesysTable";
import api from "../../services/api";
import { toast } from "react-toastify";
import { config, PURECLOUD_URL } from "../../services/utils";
import GenesysModal from "./historyFunctions/modalGhistory";
import SendHSMModal from "./historyFunctions/senHSMModal";
import MidiaSender from "./historyFunctions/sendMidia";
import AddTab from "./historyFunctions/addTab";
import EmojiSelector from "./historyFunctions/historyEmoji/popOverEmoji";
import MicIcon from "@mui/icons-material/Mic";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { Button } from "@mui/material";
import ParamLoading from "../../components/loadParams/loadingParams";
import HSM from "../screenHSM/screenHSM";
import MailIcon from "@mui/icons-material/Mail";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import VerifyFields from "./historyFunctions/verifyFields/verifyFields";
import BedtimeOffIcon from "@mui/icons-material/BedtimeOff";
import Tooltip from "@mui/material/Tooltip";
import HibernationModal from "../../components/UI/Modals/HibernationChoose";
import HibernationModal2 from "../../components/UI/Modals/HibernationModal2";
import ClientDataModal from "../../components/UI/Modals/ClientDataModal";

export default function GenesysHistory({ match }) {
  const [retornoAPI, setRetornoAPI] = useState([]);
  const [endingReturn, setEndingReturn] = useState(false);
  const [garantia, setGarantia] = useState(false);
  const [exec, setExec] = useState([true]);
  const [participantData, setParticipantData] = useState({});
  const [status, setStatus] = useState({});
  const [disabledButton, setDisabledButton] = useState(false);
  const [timerRemains, setTimerRemains] = useState("00:00:00");
  const [intervalID, setIntervalID] = useState(null);
  const [percent, setPercent] = useState(0);
  const [openModalHibernation, setOpenModelHibernation] = useState(false);
  const [allCallData, setAllCallData] = useState([]);
  const [countdowns, setCountdowns] = useState([]);
  const [dataModal, setDataModal] = useState({});
  const [openAnotherCallModal, setOpenAnotherCallModal] = useState(false);
  const [openClientDataModal, setOpenClientDataModal] = useState(false);
  const [clientData, setClientData] = useState({});

  const {
    gHistoryMidia,
    gHistoryTabulacao,
    gHistoryEmojo,
    gHistoryEndingInteraction,
    gHistoryHSM,
    garantiaAssistida,
    prolongHibernation,
  } = config.application.features;

  const { audioRecorder } = config.application.menus;

  const telefone = match.params.telefone;
  const origin = match.params.origin;
  const token = match.params.token;

  useEffect(() => {
    const loadTables = async () => {
      await api
        .get(`api/espresso/${telefone}/${origin}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })

        .then((res) => {
          if (res.data.length === 0) {
            toast.error(`O número ${telefone} não tem registros`);
          }
          setTimeout(() => {
            setExec(!exec);
          }, 10000);
          setRetornoAPI(res.data);
          return;
        })
        .catch((err) => {
          setTimeout(() => {
            setExec(!exec);
          }, 10000);
        });
      return;
    };
    loadTables();
    if (prolongHibernation) {
      getAllClientsTime();
      getStatus();
    }
  }, [exec]);

  useEffect(() => {
    let conversationID = token.split(";");

    async function loadParticipantData() {
      await api
        .get(`${PURECLOUD_URL}/conversations/messages/${conversationID[0]}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("pcToken")}`,
          },
        })
        .then((res) => {
          setParticipantData(res.data.participants[0].attributes);
          setGarantia(
            res.data.participants[0].attributes.garantiaAssistida
              ? res.data.participants[0].attributes.garantiaAssistida
              : false
          );
        });
    }

    loadParticipantData();
  }, []);

  useEffect(() => {
    const newCountDowns = allCallData.map((item) => {
      const hibernationTime = item.startHibernationTime * 60000;
      const msTimeout = hibernationTime * 0.7;
      const msPassed = new Date() - new Date(item.lastMessage);
      const msRemain = msTimeout - msPassed;
      const countdownID = setTimeout(() => {
        if (!item?.postponed && item?.postponeHibernationTimer?.length) {
          showAnotherConversations(item);
        }
      }, msRemain);

      return { id: item, countdownID: countdownID };
    });

    setCountdowns(newCountDowns);
    return () => {
      newCountDowns.forEach((countdown) => clearTimeout(countdown.countdownID));
    };
  }, [allCallData]);

  async function showAnotherConversations(item) {
    await setDataModal(item);
    handleAnotherCallModal();
  }

  async function getClientData() {
    await api
      .get(`/api/database/conversation/${origin}/${telefone}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setClientData(response.data);
      })
      .catch(() => {
        toast.error("Houve um erro ao consultar os dados do cliente");
      });
  }

  function handleAnotherCallModal() {
    if (!openAnotherCallModal) {
      setOpenAnotherCallModal(true);
    }
  }

  async function getAllClientsTime() {
    const userMail = localStorage.getItem("userName");
    await api
      .get(`/api/espresso/hibernation/status/${userMail}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        if (!allCallData.length || !deepEqual(res.data, allCallData)) {
          setAllCallData(res.data);
        }
      });
  }

  function deepEqual(obj1, obj2) {
    if (obj1 === obj2) return true;
    if (
      obj1 == null ||
      obj2 == null ||
      typeof obj1 !== "object" ||
      typeof obj2 !== "object"
    )
      return false;

    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      if (obj1.length !== obj2.length) return false;
      for (let i = 0; i < obj1.length; i++) {
        if (!deepEqual(obj1[i], obj2[i])) return false;
      }
      return true;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key]))
        return false;
    }

    return true;
  }

  function calculatePercent(lastMessage, el) {
    const timer = el * 60000;
    const lastDate = new Date(lastMessage);
    const limitTimer = lastDate.getTime() + timer;
    const nowTimer = new Date();
    const timerRemain = Math.max(limitTimer - nowTimer.getTime(), 0);
    const percents = Math.floor((timerRemain / timer) * 100);

    return percents;
  }
  async function getStatus() {
    await api
      .get(`api/espresso/hibernation/status/${telefone}/${origin}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setStatus(res.data);
        let perc = calculatePercent(
          res.data.lastMessage,
          res.data.startHibernationTime
        );
        setPercent(perc);
      })
      .catch((e) => {
        return e;
      });
  }
  async function postPaneHibernation(time) {
    const payload = { delayTime: time };
    await api
      .post(
        `api/espresso/hibernation/postpone/${telefone}/${origin}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        toast.success("Hibernção adiada com sucesso!");
        setDisabledButton(true);
      })
      .catch(() => {
        toast.error("Houve um erro ao adiar a hibernação!");
      })
      .finally(() => {
        setOpenModelHibernation(false);
      });
  }

  async function postPaneHibernationAnotherCalls(time, data) {
    const payload = { delayTime: time };
    await api
      .post(
        `api/espresso/hibernation/postpone/${data.phone}/${data.origin}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      )
      .then((res) => {
        if (time != 0) {
          toast.success("Hibernção adiada com sucesso!");
        } else {
          toast.warning("Adiamento ignorado com sucesso!");
        }
        setDisabledButton(true);
      })
      .catch(() => {
        toast.error("Houve um erro ao adiar a hibernação!");
      })
      .finally(() => {
        setOpenAnotherCallModal(false);
      });
  }

  async function endingInteraction() {
    setEndingReturn(false);
    let payload = {
      phone: telefone,
      origin: origin,
    };

    await api
      .post("api/espresso/disconnect", payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then((res) => {
        setEndingReturn(true);
        // toast.warn("Interação encerrada com sucesso");
        return;
      })
      .catch((err) => {
        setEndingReturn(true);
        toast.error("Erro #006: Não foi possível encerrar a interação");
      });
  }

  function handleHibernationModal() {
    setOpenModelHibernation(!openModalHibernation);
  }

  async function handleClientModal() {
    await getClientData();
    setOpenClientDataModal(!openClientDataModal);
  }
  return (
    <div className="spacement">
      <div className="buttonBar">
        <div className="buttons">
          {gHistoryMidia ? (
            <div className="separator">
              <GenesysModal
                buttonString={
                  <div className="button-Z">
                    <CloudUploadIcon sx={{ fontSize: "calc(6px + 1.5vw)" }} />
                    <span>enviar</span>
                  </div>
                }
                boxName="Enviar Midias"
                className="send"
                styleButton={{
                  bgcolor: "#2980B9",
                  color: "white",
                  fontSize: "calc(6px + 0.7vw)",
                  borderRadius: 2,
                  width: "calc(12px + 9vw)",
                  borderSize: 0,
                  ":hover": { bgcolor: "#60bfff" },
                }}
              >
                <MidiaSender />
              </GenesysModal>
            </div>
          ) : null}

          {gHistoryTabulacao ? (
            <div className="separator">
              <GenesysModal
                buttonString={
                  <div className="button-Z">
                    <ViewListOutlinedIcon
                      sx={{
                        fontSize: "calc(6px + 1.5vw)",
                        marginRight: "0.5vw",
                      }}
                    />
                    tabulação
                  </div>
                }
                boxName="Adicionar Tabulação"
                className="send"
                styleButton={{
                  bgcolor: "#2980B9",
                  color: "white",
                  fontSize: "calc(6px + 0.7vw)",
                  borderRadius: 2,
                  width: "calc(12px + 9vw)",
                  borderSize: 0,
                  ":hover": { bgcolor: "#60bfff" },
                }}
              >
                <AddTab />
              </GenesysModal>
            </div>
          ) : null}

          {audioRecorder ? (
            <div className="separator">
              <Button
                variant="string"
                onClick={() => {
                  window.open(
                    `${window.location.origin}/app/recordingAudio/${telefone}/${origin}/${token}`,
                    "childWindow",
                    "width=350,height=120"
                  );
                }}
                sx={{
                  bgcolor: "#2980B9",
                  color: "white",
                  fontSize: "calc(6px + 0.7vw)",
                  borderRadius: 2,
                  width: "calc(12px + 9vw)",
                  borderSize: 0,
                  ":hover": { bgcolor: "#60bfff" },
                }}
              >
                <div className="button-Z">
                  <MicIcon
                    sx={{ fontSize: "calc(6px + 1.5vw)", marginRight: "0.5vw" }}
                  />
                  Gravar
                </div>
              </Button>
            </div>
          ) : null}

          {gHistoryHSM ? (
            <div className="separator">
              <SendHSMModal
                buttonString={
                  <div className="button-Z">
                    <MailIcon
                      sx={{
                        fontSize: "calc(6px + 1.5vw)",
                        marginRight: "0.5vw",
                      }}
                    />
                    HSM
                  </div>
                }
                boxName="Enviar HSM"
                className="send"
                styleButton={{
                  bgcolor: "#2980B9",
                  color: "white",
                  fontSize: "calc(6px + 0.7vw)",
                  borderRadius: 2,
                  width: "calc(12px + 9vw)",
                  borderSize: 0,
                  ":hover": { bgcolor: "#60bfff" },
                }}
              >
                <HSM showAgendamento={true} />
              </SendHSMModal>
            </div>
          ) : null}
          {prolongHibernation ? (
            <div className="separator">
              <Tooltip title="Adiar hibernação">
                <Button
                  disabled={
                    100 - percent <= 50 ||
                    status?.postponed ||
                    !status?.postponeHibernationTimer?.length > 0
                  }
                  variant="string"
                  sx={{
                    bgcolor: "#2980B9",
                    color: "white",
                    fontSize: "calc(6px + 0.7vw)",
                    borderRadius: 2,
                    width: "calc(12px + 13vw)",
                    borderSize: 0,
                    ":hover": { bgcolor: "#60bfff" },
                  }}
                  onClick={() => handleHibernationModal()}
                >
                  {" "}
                  <div className="button-Z">
                    {" "}
                    <BedtimeOffIcon fontSize="calc(6px + 0.7vw)" /> Hibernação
                  </div>
                </Button>
              </Tooltip>
            </div>
          ) : null}
          <div className="separator">
            <Tooltip title="Dados do Cliente">
              <Button
                variant="string"
                sx={{
                  bgcolor: "#2980B9",
                  color: "white",
                  fontSize: "calc(6px + 0.7vw)",
                  borderRadius: 2,
                  width: "calc(12px + 13vw)",
                  borderSize: 0,
                  ":hover": { bgcolor: "#60bfff" },
                }}
                onClick={() => handleClientModal()}
              >
                {" "}
                <div className="button-Z"> Cliente</div>
              </Button>
            </Tooltip>
          </div>

          {garantia ? (
            <SendHSMModal
              buttonString={
                <div className="button-Z">
                  <RequestQuoteIcon
                    sx={{ fontSize: "calc(6px + 1.5vw)", marginRight: "0.5vw" }}
                  />
                  Garantia
                </div>
              }
              boxName="Solicitar Garantia Assistida"
              className="send"
              styleButton={{
                bgcolor: "#2980B9",
                color: "white",
                fontSize: "calc(6px + 0.7vw)",
                borderRadius: 2,
                width: "calc(12px + 11vw)",
                borderSize: 0,
                ":hover": { bgcolor: "#60bfff" },
              }}
            >
              <VerifyFields
                URL={`${window.location.origin}/app/ghistory/${telefone}/${origin}`}
                participantData={participantData}
              />
            </SendHSMModal>
          ) : null}
          {gHistoryEmojo ? (
            <div className="separator">
              <EmojiSelector />
            </div>
          ) : null}
        </div>

        {gHistoryEndingInteraction ? (
          <div className="rightButtons">
            {endingReturn ? (
              <Button
                variant="string"
                onClick={() => {
                  endingInteraction();
                }}
                sx={{
                  bgcolor: "#2980B9",
                  color: "white",
                  fontSize: "calc(6px + 0.7vw)",
                  borderRadius: 2,
                  width: "calc(12px + 11vw)",
                  borderSize: 0,
                  ":hover": { bgcolor: "#60bfff" },
                }}
              >
                <div className="button-Z">Encerrar</div>
              </Button>
            ) : (
              <ParamLoading />
            )}
          </div>
        ) : null}
      </div>
      <div className="genesysBox">
        <HibernationModal
          open={openModalHibernation}
          handleClose={handleHibernationModal}
          options={
            status?.postponeHibernationTimer
              ? status.postponeHibernationTimer
              : []
          }
          handleSelect={postPaneHibernation}
        />
        <HibernationModal2
          open={openAnotherCallModal}
          handleClose={() => setOpenAnotherCallModal(false)}
          data={dataModal}
          handleSelect={postPaneHibernationAnotherCalls}
        />
        <ClientDataModal
          open={openClientDataModal}
          handleModal={handleClientModal}
          clientData={clientData}
        />
        <StyledEngineProvider injectFirst>
          <NewGenesysTable
            history={retornoAPI}
            telefone={telefone}
            origin={origin}
          />
        </StyledEngineProvider>
      </div>
    </div>
  );
}
