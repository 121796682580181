import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import SmartToyIcon from "@mui/icons-material/SmartToy";
import BarChartIcon from "@mui/icons-material/BarChart";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import uraStyles from "./dashUra.module.css";
import api from "../../services/api";
import { config } from "../../services/utils";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import UraChart from "../../components/charts/uraChart";

export default function DashUra() {
  const [origin, setOrigin] = useState("");
  const [data, setData] = useState({});
  const [graph, setGraph] = useState([]);
  const [exec, setExec] = useState(false);

  function createBoxes(creating) {
    return (
      <div className={uraStyles.uraBoxes}>
        <div className={uraStyles.uraBoxesHeader}>
          {creating.icon}
          <p className="ms-2 mt-2">{creating.name}</p>
        </div>
        <div className={uraStyles.quantity}>
          <h3 className={uraStyles.value}>
            {" "}
            {creating.value >= 0 ? creating.value : 0}
          </h3>
        </div>
        <div className={uraStyles.footer}>
          <p>{creating.desc}</p>
        </div>

        {/* {creating.icon}
        <div className={uraStyles.sendInnerBox}>
          <span className={uraStyles.status}>{creating.name}</span>
          <span className={uraStyles.quantity}>
            {creating.value >= 0 ? creating.value : 0}
          </span>
          <span className={uraStyles.desc}>{creating.desc}</span>
        </div> */}
      </div>
    );
  }

  useEffect(() => {
    async function loadCall() {
      await api
        .get(`api/database/report/history24hc?origin=${origin}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          if (res.data.length === 0) {
            // toast.warn(`Ausência de dados na origin ${origin}`);
            setGraph([{ Total: 0, Bot: 0, Transbordado: 0, Hora: 0 }]);
            setData(
              apiToScreen([{ Total: 0, Bot: 0, Transbordado: 0, Hora: 0 }])
            );
          } else {
            setData(apiToScreen(res.data));
            setGraph(res.data);
          }
        })
        .catch((err) => {
          setGraph([{ Total: 0, Bot: 0, Transbordado: 0, Hora: 0 }]);
          setData(
            apiToScreen([{ Total: 0, Bot: 0, Transbordado: 0, Hora: 0 }])
          );
          toast.error("Erro #003: Falha ao acessar o banco de dados");
        });
    }

    setTimeout(() => {
      setExec(!exec);
    }, 5000);

    loadCall();
  }, [origin, exec]);

  function apiToScreen(data) {
    return data.reduce((a, c) => {
      return {
        Total: a.Total + c.Total,
        Bot: a.Bot + c.Bot,
        Transbordado: a.Transbordado + c.Transbordado,
      };
    });
  }

  return (
    <div className={uraStyles.dashContainer}>
      <div className={`d-flex justify-content-between pe-4`}>
        <div className={`titlePage ${uraStyles.textMinor}`}>
          Dashboard - Flow
        </div>
        <div className="d-flex align-items-center">
          <label className="defaultLabel me-2">Origem:</label>
          <select
            className="select-bootstrap"
            value={origin}
            onChange={(e) => setOrigin(e.target.value)}
          >
            <option defaultValue value={""}>
              Selecione a Origem
            </option>
            {config.summarize.map((value) => (
              <option value={value.origin}>{value.visualName}</option>
            ))}
          </select>
        </div>
      </div>
      <div>
        <div className={`${uraStyles.statusUra} d-flex`}>
          {createBoxes({
            name: "Bot",
            desc: "Conversas finalizadas no bot",
            value: data.Bot,
            icon: <SmartToyIcon className="statusBoxIcons" />,
          })}

          {createBoxes({
            name: "Transbordo",
            desc: "Conversas derivadas p/ operador",
            value: data.Transbordado,
            icon: <SupportAgentIcon className="statusBoxIcons" />,
          })}

          {createBoxes({
            name: "Conversação",
            desc: "Total de conversas",
            value: data.Total,
            icon: <AccountTreeIcon className="statusBoxIcons" />,
          })}
        </div>

        <div className={uraStyles.uraTitle}>
          <BarChartIcon sx={{ fontSize: "1.6vw" }} />
          <span>24 Horas</span>
        </div>
        <div className={uraStyles.uraBox}>
          <div className={uraStyles.uraGraphic}>
            <UraChart dados={graph} />
          </div>
        </div>
      </div>
    </div>
  );
}
